import React, {useState} from "react"
import {DocFooter, DocLeftMenu, pageDataDocRest as mainData, pageDataDocRestMini, DrawTableApi, RePos} from './components'
import JsonViewer from "@andypf/json-viewer/dist/esm/react/JsonViewer"

function App() {

    document.title = "REST Documentation"

    const ShowJson = true
    //const JsonViewer = (myData) => <div></div>
    //const JsonInHtml = (myData) => <div></div>
    const JsonViewer = ({ data, ...props }) => {const ref = React.useRef(null);React.useEffect(() => {if (ref.current) {ref.current.data = data}}, [data]);return <andypf-json-viewer ref={ref} {...props} />}
    const JsonInHtml = (myData) => {return <JsonViewer theme="brushtrees-dark" expanded="true" indent="2" expand-icon-type="circle" show-data-types="false" show-toolbar="false" show-size="false" show-copy="true" data={myData.props} />}

    // eventPage - start
    const [eventPage, setEventPage] = useState(false)
    function getTime () {return (new Date).getTime()}
    let timeLock = getTime() + 500
    if (!eventPage) {
        setEventPage(true)
        window.addEventListener('scroll', function(event) {if (timeLock <= getTime()) {RePos('helpListPage__mainTitle', 'helpListPage__secondTitle')}}) // scroll
        // document.addEventListener("DOMContentLoaded", function () {}) // DOMContentLoaded
        // window.addEventListener('click', function(event) {}) // click
    }
    // eventPage - end

    return (
        <div className="">
            <div className="">
                <div className="docTopMenu">
                    <div>
                        <div>
                            <div/>
                            <div/>
                        </div>
                        <div/>
                    </div>
                </div>
                <div className="docTopSubMenu">

                </div>
            </div>
            <div className="docMenuAndContent">
                <DocLeftMenu MenuItems={pageDataDocRestMini} />
                <div className="docContent">
                    <div className="docBo">
                        <div className="helpListPage">
                            {(mainData && mainData.blocks && Object.keys(mainData.blocks).length > 0) && Object.keys(mainData.blocks).map((item1, index1) => {
                                return <div key={index1}>
                                    <div id={mainData.blocks[item1].name} className="helpListPage__mainTitle">{mainData.blocks[item1].name}</div>
                                    <div>
                                        {!!mainData.blocks[item1] && Object.keys(mainData.blocks[item1].blocks).map((item2, index2) => {
                                            return <div className={"headBlock"} id={!!mainData.blocks[item1].blocks[index2].name ? mainData.blocks[item1].blocks[index2].name.split('<br/>').join('') : ''} key={index2}>
                                                {
                                                    (!!mainData.blocks[item1].blocks[index2]) && <div>
                                                        {!!mainData.blocks[item1].blocks[index2].name ? <div
                                                            id={mainData.blocks[item1].name + " - " + mainData.blocks[item1].blocks[index2].name.split('<br/>').join('')}
                                                            className="helpListPage__secondTitle">{mainData.blocks[item1].name + " - " + mainData.blocks[item1].blocks[index2].name.split('<br/>').join('')}</div> : ''}
                                                        <div>
                                                            {!!mainData.blocks[item1].blocks[index2] && !!mainData.blocks[item1].blocks[index2].blocks && Object.keys(mainData.blocks[item1].blocks[index2].blocks).map((item3, index3) => {
                                                                return <div key={item3} className="helpListPage__blocks" id={"h" + index1 + "-b" + index2}>
                                                                    <div>
                                                                        {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'title' && <div className="helpListPage__blockDescription" dangerouslySetInnerHTML={{__html: mainData.blocks[item1].blocks[index2].blocks[index3].title}}/>}
                                                                    </div>
                                                                    <div>
                                                                        {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'request' &&
                                                                            <div>
                                                                                <div className="helpListPage__blockReqType">{mainData.blocks[item1].blocks[index2].blocks[index3].reqType}</div>
                                                                                <div className="helpListPage__blockReqUrl">{mainData.blocks[item1].blocks[index2].blocks[index3].reqUrl}</div>
                                                                                <div className="helpListPage__blockDescription">Permission: {mainData.blocks[item1].blocks[index2].blocks[index3].reqPerms}</div>
                                                                            </div>}
                                                                    </div>
                                                                    <div>
                                                                        {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'parameter' &&
                                                                            <div>
                                                                                <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>
                                                                                <DrawTableApi table={mainData.blocks[item1].blocks[index2].blocks[index3].table}/>
                                                                            </div>}
                                                                    </div>
                                                                    <div>
                                                                        {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'requestExample' &&
                                                                            <div>
                                                                                <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>
                                                                                <div>{ShowJson && !!mainData.blocks[item1].blocks[index2].blocks[index3].code && <JsonInHtml props={mainData.blocks[item1].blocks[index2].blocks[index3].code}/>}</div>
                                                                            </div>}
                                                                    </div>
                                                                    <div>
                                                                        {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'answer' &&
                                                                            <div>
                                                                                <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>
                                                                                <DrawTableApi table={mainData.blocks[item1].blocks[index2].blocks[index3].table}/>
                                                                            </div>}
                                                                    </div>
                                                                    <div>
                                                                        {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'answerExample' &&
                                                                            <div>
                                                                                <div>{(!!mainData.blocks[item1].blocks[index2].blocks[index3].title && mainData.blocks[item1].blocks[index2].blocks[index3].title !== '') && <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>}</div>
                                                                                <div className={(mainData.blocks[item1].blocks[index2].blocks[index3].preCodeColor) ? 'helpListPage__blockReqTypeRed' : 'helpListPage__blockReqType'}>{mainData.blocks[item1].blocks[index2].blocks[index3].preCode}</div>
                                                                                <div>{ShowJson && !!mainData.blocks[item1].blocks[index2].blocks[index3].code && <JsonInHtml props={mainData.blocks[item1].blocks[index2].blocks[index3].code}/>}</div>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        })}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <DocFooter/>
                </div>
            </div>
        </div>
    )
}

export default App
