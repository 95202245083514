import React, {useState} from "react"
import {slGet, sSet} from "helper-func"
import {Link} from "react-scroll"

function App() {

    const PARTNER_OPT                = {lp: 'ru'}
    let iLp                          = slGet('lp', ['ru','en'],  PARTNER_OPT.lp)
    let prP = {
        ru: {
            1: 'Антивирусная защита в режиме реального времени', 2: 'Защита платежей', 3: 'Оптимизация производительности', 4: 'Определитель номера Who Calls', 5: 'Поиск утечек данных', 6: 'Защита цифровой личности', 7: 'Обнаружение и удаление вирусов силами экспертов',

            11: 'Передовая защита от программ-вымогателей и откат вредоносных действий', 12: 'Шифрование и резервное копирование файлов для защиты ценных данных', 13: 'Безопасные платежи для надежной оплаты счетов и налогов через интернет',
            14: 'Защита устройств на базе Android, чтобы сотрудники могли безопасно работать на личных смартфонах и планшетах', 15: 'Встроенный мониторинг уязвимостей для защиты бизнес-приложений', 16: '',
            17: 'Облачная консоль управления, которая сочетает простоту в использовании с передовыми инструментами защиты.', 18: 'Управление множеством рабочих станций, мобильных устройств и файловых серверов с любого устройства, подключенного к интернету.',
            19: 'Никаких дополнительных расходов на оборудование — заботы об облачной инфраструктуре берет на себя «Лаборатория Касперского».', 20: 'Веб-Контроль и Контроль устройств ', 21: 'Управление установкой исправлений и шифрованием',
            22: '', 23: 'IT administrator', 24: '',
        },
        en: {
            1: 'Real-Time Antivirus', 2: 'Online Payment Protection', 3: 'Performance Optimization', 4: 'Unlimited Superfast VPN', 5: 'Data Leak Checker', 6: 'Identity Protection', 7: 'Expert Virus Check & Removal',

            11: '<b>Advanced ransomware protection and rollback</b> to avoid someone\'s accidental click making your computers lock down ', 12: '<b>File Encryption and Backup</b> to secure your intellectual property and trade secrets ', 13: '<b>Safe Money</b> to pay bills and taxes online with confidence ',
            14: '<b>Android device protection</b> so your employees can work safely from their personal smartphones and tablets ', 15: '<b>Built-in vulnerability scanning</b> to ensure the business apps you use are safe from intrusion ', 16: '<b>Unlimited, fast VPN</b> to enjoy global content without compromising on privacy, security or speed.',
            17: 'Protect your business easily and reliably, without sacrificing your IT resources, time, or budget.', 18: 'An automated solution to reduce IT costs and free-up resources.',
            19: '', 20: 'Web Control and Device Control', 21: 'Patch management and Encryption management',
            22: 'Cloud Blocking & Security for Microsoft Office 365', 23: 'IT administrator', 24: '',
        },
    }
    let allLinks = {
        ru: {
            hm: 'https://www.kaspersky.ru/home-security',
            bs: 'https://www.kaspersky.ru/small-business-security',
            bm: 'https://www.kaspersky.ru/small-to-medium-business-security',
            be: 'https://www.kaspersky.ru/enterprise-security',
        },
        en: {
            hm: 'https://www.kaspersky.com/home-security',
            bs: 'https://www.kaspersky.com/small-business-security',
            bm: 'https://www.kaspersky.com/small-to-medium-business-security',
            be: 'https://www.kaspersky.com/enterprise-security',
        },
    }
    let allProducts = {
        hm: {
            kst: {id: "standard", name: 'Kaspersky Standard', title: 'Antivirus', ru: {profit: '', defend: 'Базовая защита',}, en: {profit: '', defend: 'Standard Plan',}, pProps: [1,2,3],},
            kpl: {id: "plus", name: 'Kaspersky Plus', title: 'Internet Security', ru: {profit: '', defend: 'Оптимальная защита',}, en: {profit: '', defend: 'Plus Plan',}, pProps: [1,2,3,4,5],},
            kpr: {id: "premium", name: 'Kaspersky Premium', title: 'Total Security', ru: {profit: 'Выгодное предложение', defend: 'Максимальная защита', gift: ['ПОДАРОК','Kaspersky Safe Kids','1 ГОД БЕСПЛАТНО'],}, en: {profit: 'Profitable proposition', defend: 'Premium Plan', gift: ['GIFT','Kaspersky Safe Kids','1 YEAR FREE']}, pProps: [1,2,3,4,5,6,7],},
        },
        bs: {
            ksos: {
                id: 'ksos',
                name: 'Kaspersky Small Office Security',
                ru: {namePlus: 'Есть защита - есть бизнес!', title: 'Kaspersky Small Oﬀice Security сочетает в себе простоту защиты домашнего компьютера и специализированные возможности для обеспечения безопасности вашего бизнеса.Решение, работающее по принципу "установил и забыл".',},
                en: {namePlus: 'Always-on security\n for always-on businesses', title: 'Kaspersky Small Oﬀice Security combines the simplicity of home PC protection with special capabilities to keep your business safe while employees are working. With ‘set and forget’ security, it protects your Windows and Mac PCs and laptops, as well as your Windows file servers, to secure the files you value most.',},
                pProps: [11,12,13,14,15,16],
            },
            kesc: {
                id: 'kesc',
                name: 'Kaspersky Endpoint Security Cloud',
                ru: {namePlus: 'Надежная защита вашего бизнеса в любое время и в любом месте', title: 'Разработанное специально для компаний малого и среднего бизнеса, решение Kaspersky Endpoint Security Cloud позволяет управлять безопасностью рабочих мест, мобильных устройств и файловых серверов в любое время из любой точки мира.',},
                en: {namePlus: 'Straightforward protection for your business - wherever you’re heading', title: 'Offers small and medium sized businesses effortless yet complete cloud-based protection against known and unknown threats – including cryptors, ransomware and other attacks. All without making demands on your time or budget, because we know just how stretched smaller business resources can be!',},
                pProps: [17,18,19,22,23],
            },
            kescp: {
                id: 'kescp',
                name: 'Kaspersky Endpoint Security Cloud Plus',
                ru: {namePlus: 'Надежная защита вашего бизнеса в любое время и в любом месте', title: 'Разработанное специально для компаний малого и среднего бизнеса, решение Kaspersky Endpoint Security Cloud позволяет управлять безопасностью рабочих мест, мобильных устройств и файловых серверов в любое время из любой точки мира.',},
                en: {namePlus: 'Straightforward protection for your business - wherever you’re heading', title: 'Offers small and medium sized businesses effortless yet complete cloud-based protection against known and unknown threats – including cryptors, ransomware and other attacks. All without making demands on your time or budget, because we know just how stretched smaller business resources can be!',},
                pProps: [17,18,19,22,23,20,21],
            },
        },
        bm: {
            kes_bm: {
                id: 'kes_bm',
                ru: {name: 'Endpoint Security для бизнеса Расширенный', title: 'Уникальная адаптивная система безопасности',},
                en: {name: 'Endpoint Security Cloud', title: 'Straightforward protection for your business - wherever you’re heading',},
            },
            kts_bm: {
                id: 'kts_bm',
                ru: {name: 'Total Security для бизнеса', title: 'Максимальная защита каждого аспекта вашего бизнеса',},
                en: {name: 'Endpoint Security for Business Select', title: 'Protection and control you can trust – for every endpoint',},
            },
            kedr_bm: {
                id: 'kedr_bm',
                ru: {name: 'EDR для бизнеса Оптимальный', title: 'Защита конечных точек, усиленная технологиям EDR',},
                en: {name: 'Endpoint Security for Business Advanced', title: 'Adaptive security like no other',},
            },
            ktsp_bm: {
                id: 'ktsp_bm',
                ru: {name: 'Total Security Plus для бизнеса', title: 'Cамые мощные технологии защиты корпоративных сетей',},
                en: {name: 'Endpoint Detection and Response Optimum', title: 'Build true defense-in-depth and boost security efficiency',},
            },
        },
        be: {
            ksf: {
                id: 'ksf',
                ru: {name: 'Базовая защита', title: 'Защита от массовых угроз', message: 'Для компаний любого размера и сферы деятельности',},
                en: {name: 'Security Foundations', title: 'For every organization', message: 'Products and services that automatically block the most threats',},
            },
            kos: {
                id: 'kos',
                ru: {name: 'Оптимальная защита', title: 'Защита от передовых угроз', message: 'Для небольших компаний с базовой ИБ-экспертизой',},
                en: {name: 'Optimum Security ', title: 'For small IT security teams', message: 'Products and services that protect against evasive threats ',},
            },
            kes: {
                id: 'kes',
                ru: {name: 'Экспертная защита', title: 'Защита от целевых атак и APT-угроз', message: 'Для средних и крупных компаний с опытной ИБ-службой',},
                en: {name: 'Expert Security ', title: 'For fully-formed IT security and SOC teams', message: 'Extended detection and response to confront complex and APT-like attacks',},
            },
        },
    }
    let lPack = {
        ru: {
            headMenu: {products: 'Продукты', about: 'О нас', partners: 'Партнёры', footer: 'Контакты',},
            subMenu: {hm: 'Для дома', bs: 'Для малого бизнеса', bm: 'Для среднего бизнеса', be: 'Для крупного бизнеса'},
            buttonMore: 'Подробнее',
            address: 'Россия, Москва, ул. Правды, д. 8 корп. 13',
        },
        en: {
            headMenu: {products: 'Products', about: 'About us', partners: 'Partners', footer: 'Contacts',},
            subMenu: {hm: 'Home', bs: 'Business Small', bm: 'Business Medium', be: 'Business Enterprice'},
            buttonMore: 'Learn More',
            address: 'Russia, Moscow, st. Pravdy, building 8, corpus 13',
        },
    }

    const [popupMenu, setPopupMenu] = useState(false)//false
    const [popupLang, setPopupLang] = useState(false)//false
    const [subMenu, setSubMenu] = useState('hm')//hm/bs/bm/be

    function pageClick (e) {if (e.target.className !== 'pClose') {setPopupLang(false)}}
    function fPopupMenu () {setPopupLang(false);setPopupMenu(!popupMenu)}
    function fPopupLang () {setPopupMenu(false);setPopupLang(!popupLang)}
    function fSetLang (lang = 'ru') {if ((['ru','en']).indexOf(lang) > -1) {sSet('lp', lang)}}
    function fSubMenuChange (tab = 'hm') {setSubMenu(tab)}

    return (
        <div className="App" id="page" onClick={e => pageClick(e)}>

            <div id="header">
                <div>
                    <div>
                        <a href={document.location.href}><img alt="zscuare" src={require("./img/logo1.png")} /></a>
                        <div id="header__menu">
                            <div>
                                {
                                    Object.keys(lPack[iLp].headMenu).map((itemM, i) => {
                                        return <Link key={i} to={'' + itemM} spy={true} smooth={true} offset={-100} duration={500} href={'#' + itemM}>{lPack[iLp].headMenu[itemM]}</Link>
                                    })
                                }
                            </div>
                            <div>
                                <div onClick={_ => fPopupMenu()}><div className="pClose" dangerouslySetInnerHTML={{__html: (!popupMenu ? "&#9776;" : "&#10008;")}} /></div>
                                {
                                    popupMenu && <div>
                                        {
                                            Object.keys(lPack[iLp].headMenu).map((itemM, i) => {
                                                return <Link className="pClose" onClick={_ => setPopupMenu(false)} key={i} to={'' + itemM} spy={true} smooth={true} offset={-100} duration={500} href={'#' + itemM}>{lPack[iLp].headMenu[itemM]}</Link>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="pClose" onClick={_ => fPopupLang()}>
                            <div className="pClose">{iLp === 'ru' ? 'Русский' : 'English'}</div>
                            <div className="pClose" dangerouslySetInnerHTML={{__html: (!popupLang ? "&#9660;" : "&#9650;")}} />
                            {
                                popupLang && <div className="pClose">
                                    <div className="pClose" onClick={_ => fSetLang('ru')}>Русский</div>
                                    <div className="pClose" onClick={_ => fSetLang('en')}>English</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div id="products__menu">
                <div>
                    <div>
                        <div>
                            {
                                Object.keys(lPack[iLp].subMenu).map((item, i) => {
                                    return <div className={item === subMenu ? 'active' : ''} key={i} onClick={_ => fSubMenuChange(item)}><div>{lPack[iLp].subMenu[item]}</div></div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                subMenu === 'hm' && <div id="products">
                    <div>
                        <div>
                            <div />
                            <div>
                                {
                                    Object.keys(allProducts.hm).map((item, i) => {
                                        return <div key={i} id={allProducts.hm[item].id}>
                                            <div>{allProducts.hm[item][iLp].profit}</div>
                                            <div>{allProducts.hm[item][iLp].defend}</div>
                                            <div>
                                                <div><img alt={allProducts.hm[item].id} src={require("./img/" + allProducts.hm[item].id + ".png")} /></div>
                                                <div>{allProducts.hm[item].name}</div>
                                                <div>{allProducts.hm[item].title}</div>
                                                <div>Windows® | macOS® | Android™ | iOS®</div>
                                                <div>{allProducts.hm[item].pProps.map(itemP => {return <div key={itemP}><div className={"svg_check" + " svg_color_" + allProducts.hm[item].id} /><div>{prP[iLp][itemP]}</div></div>})}</div>
                                                <div>{allProducts.hm[item][iLp].gift && <div><div>{allProducts.hm[item][iLp].gift[0]}</div><div>{allProducts.hm[item][iLp].gift[1]}</div><div>{allProducts.hm[item][iLp].gift[2]}</div></div>}</div>
                                                <div><a target="_blank" href={allLinks[iLp].hm}><div>{lPack[iLp].buttonMore}</div></a></div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                subMenu === 'bs' && <div id="products_bs">
                    <div>
                        <div>
                            <div>
                                {
                                    Object.keys(allProducts.bs).map((item, i) => {
                                        return <div id={item} key={i}>
                                            <div>
                                                <div><img alt={item} src={require("./img/" + allProducts.bs[item].id + ".png")} /></div>
                                                <div>{allProducts.bs[item].name}</div>
                                                <div>{allProducts.bs[item][iLp].namePlus}</div>
                                                <div dangerouslySetInnerHTML={{__html: allProducts.bs[item][iLp].title}} />
                                                <div>{allProducts.bs[item].pProps.map(itemP => {
                                                    if (prP[iLp][itemP] !== "") {
                                                        return <div key={itemP}><div className={"svg_check" + " svg_color_k"} /><div dangerouslySetInnerHTML={{__html: prP[iLp][itemP]}} /></div>
                                                    }
                                                    return null
                                                })}</div>
                                                <div><a target="_blank" href={allLinks[iLp].bs}><div>{lPack[iLp].buttonMore}</div></a></div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                subMenu === 'bm' && <div id="products_bm">
                    <div>
                        <div>
                            <div>
                                {
                                    Object.keys(allProducts.bm).map((item, i) => {
                                        return <div id={item} key={i}>
                                            <div>
                                                <div><img alt={item} src={require("./img/" + allProducts.bm[item].id + ".png")} /></div>
                                                <div>{allProducts.bm[item][iLp].name}</div>
                                                <div>{allProducts.bm[item][iLp].title}</div>
                                                <div><a target="_blank" href={allLinks[iLp].bm}><div>{lPack[iLp].buttonMore}</div></a></div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                subMenu === 'be' && <div id="products_be">
                    <div>
                        <div>
                            <div>
                                {
                                    Object.keys(allProducts.be).map((item, i) => {
                                        return <div id={item} key={i}>
                                            <div>
                                                <div><img alt={item} src={require("./img/" + allProducts.be[item].id + "_be.png")} /></div>
                                                <div>{allProducts.be[item][iLp].name}</div>
                                                <div>{allProducts.be[item][iLp].title}</div>
                                                <div dangerouslySetInnerHTML={{__html: allProducts.be[item][iLp].message}} />
                                                <div><a target="_blank" href={allLinks[iLp].be}><div>{lPack[iLp].buttonMore}</div></a></div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div id="about">
                <div>
                    <div>
                        <div>{lPack[iLp].headMenu.about}</div>
                        {
                            iLp === 'ru' && <div>
                                Компания "З квадрат" выступает в роли дистрибьютора антивирусных решений по подписке "Лаборатории Касперского" в крупнейших проектах на рынке SaaS/VAS услуг. Среди наших партнеров на территории РФ и стран СНГ присутствуют ОАО «Вымпелком» (торговая марка Beeline), ОАО “Мобильные ТелеСистемы” (торговая марка МТС), Ростелеком , Дом.ру и многие другие.<br />
                                <br />
                                Компания "З квадрат" предлагает своим клиентам:<br />
                                Только лучшие и самые надежные решения от компании Лаборатории Касперского и других производителей ПО.<br />
                                Всестороннюю экспертизу по техническому, коммерческому и юридическому сопровождению SaaS/VAS проектов в крупных телеком компаниях, дата центрах и реселлерах.<br />
                                Комплекс услуг по разработке индивидуальных решений для заказчиков.<br />
                                <br />
                                Уникальная платформа компании -  Zsquare Antivirus Subscription System (ZASS) для партнеров Лаборатории Касперского (дистрибьюторов, операторов, реселлеров), которую можно наполнять сторонними продуктами. ZASS является промежуточной платформой для заказа B2C и B2B. Через предоставляемый веб-портал Вы можете управлять всеми заказчиками/заказами, видеть статистику и отчеты. Использование ZASS существенно сокращает цикл запуска продаж B2B продуктов по подписке для дистрибьютора и реселлера, а также, практически сводит на нет финансовые затраты по подключению к этому каналу продаж.<br />
                                <br />
                                Компания "З квадрат" предлагает Вам рассмотреть предложение о сотрудничестве в сфере предоставлении сервиса интернет-безопасности для абонентов широкополосного доступа в Интернет (ШПД) и мобильного интернета.<br />
                                <br />
                                Основные характеристики и преимущества сервиса:<br />
                                <br />
                                Высокая прибыльность услуги для Интернет-оператора:<br />
                                • использование в сервисе популярных продуктов;<br />
                                • отсутствие расходов на интеграцию биллинга с сервисом;<br />
                                • техническую и сервис поддержку операторов;<br />
                                • обучение специалистов call center;<br />
                                • конкурентные цены продуктов для конечных пользователей.<br />
                                <br />
                                Простота внедрения и поддержки:<br />
                                • решение не требует выделения дополнительной инфраструктуры на стороне Интернет - оператора;<br />
                                • обеспечение поддержки Оператора на весь период внедрения и функционирования сервиса;<br />
                            </div>
                        }
                        {
                            iLp === 'en' && <div>
                                The Z Square company acts as a distributor of anti-virus solutions for Kaspersky Lab subscriptions in large scale SaaS/VAS related projects. The list of our partners in the Russian Federation and CIS countries includes OJSC Vimpelcom (Beeline trademark), OJSC Mobile TeleSystems (MTS trademark), Rostelecom, Dom.ru and many others.<br />
                                <br />
                                The Z Square company offers only the best and the most reliable solutions from Kaspersky Lab and other software manufacturers,  and has comprehensive expertise in technical, commercial and legal support of SaaS/VAS projects in large telecom companies, data centers, and resellers. The company provides unique custom solution development on the Client’s requirements.<br />
                                <br />
                                The Z Square company offer includes the Zsquare Antivirus Subscription System (ZASS) platform for Kaspersky Lab partners (distributors, operators, resellers), which can be filled with third-party products. ZASS is an interim platform for B2C and B2B orders. Our web portal provides seamless access and easy management and tracking of all customers/orders, statistics and reports. Using ZASS significantly shortens the launch cycle for sales of B2B subscription products for distributors and resellers, and eliminates connecting/integration costs to the sales channel.<br />
                                <br />
                                The Z Square company invites you to consider a proposal for cooperation in providing Internet security services for broadband Internet access (BBA) and mobile Internet subscribers.<br />
                                <br />
                                The main characteristics and advantages of the service:<br />
                                <br />
                                High profitability of the service for the Internet operator:<br />
                                • usage of popular products in the service;<br />
                                • no costs for billing integration with the service;<br />
                                • technical and service support for operators;<br />
                                • training for call center specialists;<br />
                                • competitive product prices for end users.<br />
                                <br />
                                Ease of implementation and support:<br />
                                • The solution does not require the allocation of additional infrastructure on the side of the Internet operator;<br />
                                • Operator support is provided for the entire period of implementation and operation of the service;<br />
                                • The solution uses constantly updated and improved versions of antivirus products. The transition to new versions is carried out automatically.<br />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div id="partners">
                <div>
                    <div>
                        <div>{lPack[iLp].headMenu.partners}</div>
                        <div id="partner">
                            <img alt="" src={require("./img/pE.png")} />
                            <img alt="" src={require("./img/p2.png")} />
                            <img alt="" src={require("./img/p3.png")} />
                            <img alt="" src={require("./img/p4.png")} />
                            <img alt="" src={require("./img/p5.jpg")} />
                            <img alt="" src={require("./img/p6.png")} />
                            <img alt="" src={require("./img/p7.png")} />
                            <img alt="" src={require("./img/p8.jpg")} />
                            <img alt="" src={require("./img/p9.png")} />
                            <img alt="" src={require("./img/pA.png")} />
                            <img alt="" src={require("./img/pB.png")} />
                            <img alt="" src={require("./img/pC.png")} />
                            <img alt="" src={require("./img/pD.png")} />
                            <img alt="" src={require("./img/p1.png")} />
                        </div>
                    </div>
                </div>
            </div>

            <div id="footer">
                <div>
                    <div>
                        <div>{lPack[iLp].headMenu.footer}</div>
                        <div>
                            {lPack[iLp].address}<br />
                            +7 (495) 280-36-02, <a target="_blank" href="mailto:az@zsquare.ru">az@zsquare.ru</a><br />
                            Zscuare &copy; {(new Date()).getFullYear()}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default App
