import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ULink from "./uLink"
import URest from './docRest'
import UGui from './docGui'
import './App.css'


function App() {
    return <Routes>
        <Route path="/doc-gui/*" element={<UGui />} />
        <Route path="/doc-gui" element={<UGui />} />
        <Route path="/doc-rest/*" element={<URest />} />
        <Route path="/doc-rest" element={<URest />} />
        <Route path="/*" element={<ULink />} />
    </Routes>
}

export default App
